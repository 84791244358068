import React from 'react';
import PropTypes from 'prop-types';
import withRoot from '../withRoot';
import { withStyles } from '@material-ui/core';
import Layout from '../components/layout';
import SEO from '../components/seo';
import MetaPanel from '../components/meta-panel';
import Link from '@material-ui/core/Link';
import Message from '../components/message';

const styles = (theme) => ({
    content: {
        padding: `0 20px 10px`
    }
});

class NotFoundPage extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <Layout>
                <SEO title={`404: Not found`} />
                <MetaPanel title={`404 Not Found`} />
                <div className={classes.content}>
                    <Message type={`warning`} title={`No quest found!`}>
                        <span>
                            Sorry no quests found here, you must be in the wrong
                            realm! <Link href={`/`}>Go back home?</Link>
                        </span>
                    </Message>
                </div>
            </Layout>
        );
    }
}

NotFoundPage.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

export default withRoot(withStyles(styles, { withTheme: true })(NotFoundPage));
